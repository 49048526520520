<template>
    <div>
        <Header backType="blank" :back="true">
            <template v-if="event.tenant_code == 'roche'">
                <span slot="headText" style="font-size: 0.17rem">上传结算水单</span>
            </template>
        </Header>

        <div class="publicHead">
            <template v-if="event.tenant_code != 'roche'">
                <p>更新文件</p>
            </template>
            <template v-if="event.tenant_code == 'roche'">
                <span style="    font-size: 12px;"> <span style="color:red">*</span>如果实际水单与预计水单不一致，请在此处补传实际结算水单</span>
            </template>
        </div>

        <template v-if="event.tenant_code !== 'roche'">
            <div class="normalInputRow">
                <MInput title="实际人数" pla="请输入实际人数" type="tel" v-model="qty"></MInput>
            </div>
        </template>

        <template v-if="event.tenant_code != 'roche'">
            <div class="upload-sub-title">更新水单</div>
        </template>

        <template v-if="event.tenant_code == 'roche'">
            <div class="upload-sub-title">上传文件</div>
        </template>

        <div class="paymentInvoice1">
            <viewer :images="waterFilesList" class="imgContainer">
                <template v-for="list in waterFilesList">
                    <div :key="list.url">
                        <img :src="list.url" alt />
                        <Svgs color="#FF484D" classes="paymentDelete" name="iconshanchu1" @click.native="deleteInvoice(list, 'water')"></Svgs>
                    </div>
                </template>
                <div class="uploadImg" @click="openBtnGroup('water')">
                    <Svgs color="#FFFFFF" name="iconadd-copy"></Svgs>
                </div>
            </viewer>
        </div>

        <template v-if="event.tenant_code !== 'roche'">
            <div class="upload-sub-title">更新发票</div>
            <div class="paymentInvoice1">
                <viewer :images="invoiceFilesList" class="imgContainer">
                    <template v-for="list in invoiceFilesList">
                        <div :key="list.url">
                            <img :src="list.url" alt />
                            <Svgs color="#FF484D" classes="paymentDelete" name="iconshanchu1" @click.native="deleteInvoice(list, 'invoice')"></Svgs>
                        </div>
                    </template>
                    <div class="uploadImg" @click="openBtnGroup('invoice')">
                        <Svgs color="#FFFFFF" name="iconadd-copy"></Svgs>
                    </div>
                </viewer>
            </div>
        </template>

        <Btn btnClass="normalBtn invoiceSubmit" @callBack="submitInvoice">
            <span slot="btnContent">提交</span>
        </Btn>
        <BtnGroup ref="btnGroup" :btnList="btnList" />
        <MessageBox ref="remove" type="tips" messageTxt="确定删除图片？" @onOk="remove"></MessageBox>
    </div>
</template>

<script>
import { miceService } from "@/service/miceService.js";
import { mapGetters } from "vuex";
import { saveQty } from "@/config/uploadInvocie.js";
import BtnGroup from "./BtnGroup";
import { UploadPlugOCR,UploadPlug} from "smart-filer-vue";
import { SmartStorage } from "smart-core-util";
const uploadInstance = new UploadPlugOCR({
    host: process.env.VUE_APP_GATEWAY,
});
const uploadInstance2 = new UploadPlug({
    host: process.env.VUE_APP_GATEWAY,
});
export default {
    name: "UpdateFile",
    computed: {
        ...mapGetters(["event", "orderDetail"]),
    },
    components: {
        BtnGroup,
    },
    data() {
        return {
            qty: "",
            waterFilesList: [],
            invoiceFilesList: [],
            btnList: [
                {
                    txt: "拍照",
                    callback: this.graph,
                },
                {
                    txt: "从相册选取",
                    callback: this.album,
                },
            ],
            type: "",
        };
    },
    mounted() {
        this.qty = this.orderDetail.actualQty || this.orderDetail.realQty;
        this.getWaterFiles();
        this.getInvoiceFiles();
    },
    methods: {
        getWaterFiles() {
            uploadInstance.queryFile({
                filterData: {
                    filterData: {
                        catalog: "水单",
                        processId: this.orderDetail.proposalId,
                        relatedId: this.orderDetail.itemId,
                        relatedType: "settle_reback",
                    },
                },
                callback: (data) => {
                    this.waterFilesList = data.content;
                },
            });
        },
        getInvoiceFiles() {
            uploadInstance.queryFile({
                filterData: {
                    filterData: {
                        catalog: "发票",
                        itemId: this.orderDetail.itemId,
                        processId: this.orderDetail.proposalId,
                        relatedType: "Invoice_reback",
                    },
                },
                callback: (data) => {
                    this.invoiceFilesList = data.content;
                },
            });
        },
        async openBtnGroup(type) {
            this.type = type;
            if (window.flutter_inappwebview) {
                let uploadFileData = {
                    hideCamera: false,
                    hideGallery: false,
                    hidePdfAndOfd: true,
                };
                this.iJsBridge.uploadFile(uploadFileData, this.mappingAsync);
                return;
            }
            this.$refs.btnGroup.openPop();
        },
        //拍照
        graph() {
            this.$refs.btnGroup.openPop();
            let _this = this;
            uploadInstance2.cameraFile({
                callback: (data) => {
                    uploadInstance.addAsync({
                        fileList: data,
                        ossKey: "",
                        bucketAlias: "SmartEvent",
                        callback: (result) => {
                            _this.mappingAsync(result);
                        },
                    });
                },
            });
        },
        //相册选取
        album() {
            this.$refs.btnGroup.openPop();
            let _this = this;
            uploadInstance.localUpload({
                callback: (data) => {
                    uploadInstance.addAsync({
                        fileList: data,
                        ossKey: "",
                        bucketAlias: "SmartEvent",
                        callback: (result) => {
                            _this.mappingAsync(result);
                        },
                    });
                },
            });
        },
        mappingAsync(result) {
            let mappingData;
            if (this.type === "water") {
                mappingData = {
                    catalog: "水单",
                    processId: this.orderDetail.proposalId,
                    relatedId: this.orderDetail.itemId,
                    relatedType: "settle_reback",
                };
            } else {
                mappingData = {
                    catalog: "发票",
                    itemId: this.orderDetail.itemId,
                    processId: this.orderDetail.proposalId,
                    relatedType: "Invoice_reback",
                };
            }
            uploadInstance.mappingAsync({
                fileKeys: [result[0].content.fileKey],
                mappingData: mappingData,
                callback: () => {
                    if (this.type === "water") {
                        this.getWaterFiles();
                    } else {
                        this.getInvoiceFiles();
                    }
                },
            });
        },
        deleteInvoice(list, type) {
            this.type = type;
            this.list = list;
            this.$refs.remove.openPop();
        },
        remove() {
            uploadInstance.deleteAsync({
                fileItem: {
                    fileKey: this.list.fileKey,
                },
                bucketAlias: "SmartEvent",
                callback: () => {
                    if (this.type === "water") {
                        this.getWaterFiles();
                    } else {
                        this.getInvoiceFiles();
                    }
                },
            });
        },
        submitInvoice() {
            let params = {
                itemId: this.orderDetail.itemId,
                proposalId: this.orderDetail.proposalId,
                qty: this.qty,
            };

            if (event.tenant_code !== "roche") {
                let validateResult = this.validate(params, saveQty);
                if (!validateResult) return;
            }

            miceService.saveQtyExpenses(params).then((res) => {
                if (res && res.success) {
                    this.$router.push({
                        path: "/orderList",
                    });
                }
            });
        },
    },
};
</script>

<style>
@import "../OrderSettle/OrderSettle.scss";

.invoiceSubmit {
    margin-top: 0.1rem !important;
}

.paymentBtnRow {
    margin-bottom: 0 !important;
}
</style>
